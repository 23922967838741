import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MainLayout } from '../../components/MainLayout';
import useOnInitialMount from '../../hooks/useOnInitialMount';
import { useJobs } from '../../middleware/useJobs';
import { APP_PATHS } from '../../routes/paths';
import {
  questionTypeState,
  selectedCompanyState,
} from '../../states/recording';

export default function ConfirmInterview() {
  const getJobs = useJobs();
  const navigate = useNavigate();
  const questionType = useRecoilValue(questionTypeState);
  const setSelectedCompany = useSetRecoilState(selectedCompanyState);
  const {
    state: { step, selectedJob, selectedCompany, stepsAry },
  } = useLocation();
  const currentMenuStep = stepsAry[step] ?? {};
  const { name: companyName } = selectedCompany ?? {};
  const { title: jobName } = selectedJob ?? {};
  const companyJSX = companyName ? <b> at {companyName}</b> : '';

  useOnInitialMount(() => {
    getJobs.mutate({});
  });

  const onSubmit = useCallback(() => {
    setSelectedCompany(selectedCompany);
    navigate(APP_PATHS.startAnswering, {
      state: {
        step: step + 1,
        selectedJob,
        selectedCompany,
        stepsAry,
      },
    });
  }, [
    setSelectedCompany,
    selectedCompany,
    navigate,
    step,
    selectedJob,
    stepsAry,
  ]);

  const footer = (
    <Box display="flex" justifyContent="flex-end" className="px-4 py-2 ">
      <Button
        variant="outlined"
        onClick={() => navigate(-1)}
        className="!rounded-full !normal-case"
      >
        Make adjustments
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ textTransform: 'none' }}
        onClick={onSubmit}
        className="!rounded-full !normal-case !ml-3"
      >
        Let's go!
      </Button>
    </Box>
  );

  return (
    <MainLayout footer={footer}>
      <Box className="!flex !flex-1 flex-col h-screen">
        <Box className="flex flex-row justify-between mt-10 mb-8 ">
          <Typography
            variant="body1"
            className="!font-bold !text-sm cursor-pointer hover:underline"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Typography>
          {currentMenuStep?.canSkip && (
            <Typography
              variant="body1"
              className="!font-bold !text-sm cursor-pointer hover:underline"
              onClick={() => {
                navigate(stepsAry[step + 1].path, {
                  state: {
                    step: step + 1,
                    stepsAry,
                    selectedJob,
                  },
                });
              }}
            >
              Skip
            </Typography>
          )}
        </Box>
        <div className="flex h-screen flex-col">
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={styles.title}
            className="!mb-16 !mt-0"
          >
            Confirm your interview
          </Typography>
          {questionType !== 'both' ? (
            <Typography variant="h4" sx={styles.title} className="!mb-16">
              You’re about to practice <b>{questionType} questions</b> for the{' '}
              <b>{jobName + ' '}</b>position
              {companyName && companyJSX}.
            </Typography>
          ) : (
            <Typography variant="h4" sx={styles.title} className="!mb-16">
              You’re about to practice{' '}
              <b>{questionType} behavioral & hypothetical questions</b> for the{' '}
              <b>{jobName + ' '}</b>
              {companyName && companyJSX}.
            </Typography>
          )}
          <Typography variant="h4" sx={styles.title}>
            Are you ready?
          </Typography>
        </div>
      </Box>
    </MainLayout>
  );
}

const styles: { [key: string]: SxProps<Theme> } = {
  title: {
    mt: 4,
  },
};
